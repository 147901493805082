<template>
    <div class="top-menu" :class="{'fixed-menu': fixedMenu}">
        <div class="main-topmenu">
            <img class="logo" @click="jumpTo('index')" :src="require('@/assets/images/index/logo.png')" alt="">
            <ul class="topmenu-list">
                <li :class="{'active': $route.path.indexOf('index') > 0}" @click="jumpTo('index')">首页</li>
                <li :class="{'active': $route.path.indexOf('course') > 0}" @click="jumpTo('course')">课程建设</li>
                <li :class="{'active': $route.path.indexOf('sourcestore') > 0}" @click="jumpTo('sourceStore')">专业资源库</li>
                <!-- <li :class="{'active': $route.path.indexOf('practice') > 0}" @click="jumpTo('practice')">综合实训</li> -->
                <li :class="{'active': $route.path.indexOf('exams') > 0}" @click="jumpTo('exams')">考试题库</li>
                <li :class="{'active': $route.path.indexOf('cooperate') > 0}" @click="jumpTo('cooperate')">合作院校</li>
                <li :class="{'active': $route.path.indexOf('case') > 0}" @click="jumpTo('case')">实施案例</li>
                <li :class="{'active': $route.path.indexOf('project') > 0}" @click="jumpTo('project')">项目合作</li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    components: {},
    props: {},
    data() {
        return {
            fixedMenu: false
        }
    },
    created() {},
    mounted() {
        window.addEventListener('scroll', () => {
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            this.fixedMenu = scrollTop > 10
        })
    },
    methods: {
        jumpTo(name) {
            this.$router.push({name})
            document.documentElement.scrollTop = 0
        },
        jumpToIndex() {

        },
    }
}
</script>
<style lang='scss' scoped>
$mainWidth: 1180px;
.top-menu {
    background-color: transparent;
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
    .main-topmenu {
        width: $mainWidth;
        margin: auto;
        height: 60px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .logo {
            height: 40px;
            cursor: pointer;
        }
        .topmenu-list {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            &>li {
                margin-left: 40px;
                font-size: 16px;
                font-weight: 400;
                color: #666666;
                line-height: 22px;
                cursor: pointer;
                &:hover {
                    color: #4A90E2;
                }
            }
            .active {
                font-weight: 600;
                color: #0768AB;
            }
        }
    }
}
.fixed-menu {
    backdrop-filter: blur(20px);
}
// 火狐
@-moz-document url-prefix() {
   .fixed-menu {
        background: #fff !important;
        box-shadow: 0 4px 10px rgba(0 , 0, 0, .1);
    } 
}
@media all and (min-width:0) {
    .fixed-menu {
        background: #fff !important;
        box-shadow: 0 4px 10px rgba(0 , 0, 0, .1);
    } 
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none){
    .fixed-menu {
        background: #fff !important;
        box-shadow: 0 4px 10px rgba(0 , 0, 0, .1);
    } 
}
</style>