<template>
    <div class="back-top" v-show="showBackTop" @click="scrollTopTop"></div>
</template>
<script>
export default {
    components: {},
    props: {},
    data() {
        return {
            showBackTop: false
        }
    },
    created() {},
    mounted() {
        window.addEventListener('scroll', () => {
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            // let height = document.body.clientHeight;
            this.showBackTop = scrollTop > 10
            // this.showBackTop = scrollTop > height
        })
    },
    methods: {
        scrollTopTop() {
            document.documentElement.scrollTop = 0
        }
    }
}
</script>
<style lang='scss' scoped>
.back-top {
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 40px;
    height: 40px;
    background: url('./../../assets/images/index/top.png') no-repeat center;
    background-size: cover;
    cursor: pointer;
    &:hover {
        background-image: url('./../../assets/images/index/topHover.png');
    }
}
</style>