<template>
    <div class="common-footer">
        <div class="footer-top">
            <div>
                <img class="footer-logo" :src="require('@/assets/images/index/logo.png')" alt="">
                <div class="footer-contact">
                    <div class="footer-items">
                        <p>高校合作伙伴与专家作者招募</p>
                        <span>薛老师</span>
                        <span>电话：010-83470435</span>
                        <span>xuesh@tup.tsinghua.edu.cn</span>
                    </div>
                    <div class="footer-items">
                        <p>区域代理商招募</p>
                        <span>屈老师</span>
                        <span>电话：010-83470453</span>
                        <span>qumy@tup.tsinghua.edu.cn</span>
                    </div>
                    <div class="footer-items">
                        <p>教具等硬件商合作招募</p>
                        <span>王老师</span>
                        <span>电话：010-83470456</span>
                        <span>wangying@tup.tsinghua.edu.cn</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-bottom">
            <div>© {{new Date().getFullYear()}} <a href="http://tup.com.cn/" target="_blank">清华大学出版社有限公司</a>. 版权所有.  <a href="http://beian.miit.gov.cn/" target="_blank">京ICP备10035462号-20</a></div>
        </div>
    </div>
</template>
<script>
export default {
    components: {},
    props: {},
    data() {
        return {
            
        }
    },
    created() {},
    mounted() {},
    methods: {
        
    }
}
</script>
<style lang='scss' scoped>
$mainWidth: 1180px;
.common-footer {
    width: 100%;
    .footer-top {
        height: 260px;
        background: #E0E0E0;
        &>div {
            width: $mainWidth;
            margin: auto;
            padding-top: 36px;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            .footer-logo {
                height: 42px;
                padding-top: 7px;
                padding-right: 120px;
            }
            .footer-contact {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
                .footer-items {
                    margin-left: 90px;
                    &>p {
                        font-size: 16px;
                        font-weight: 600;
                        color: #666666;
                        line-height: 32px;
                        margin-bottom: 14px;
                    }
                    &>span {
                        display: block;
                        font-size: 14px;
                        font-weight: 400;
                        color: #666666;
                        line-height: 24px;
                    }
                }
            }
        }
    }
    .footer-bottom {
        height: 40px;
        background: #4A4A4A;
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 40px;
        &>div {
            width: $mainWidth;
            margin: auto;
            text-align: left;
            &>a {
                color: #fff;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
</style>