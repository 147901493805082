<template>
    <div class="index-container">
        <CommonHeader />
        <div class="index-mainwidth"><router-view /></div>
        <CommonFooter />
        <CommonTop />
    </div>
</template>
<script>
import CommonFooter from '@/components/common/CommonFooter.vue'
import CommonHeader from '@/components/common/CommonHeader.vue'
import CommonTop from '@/components/common/CommonTop.vue'
export default {
    components: { CommonHeader, CommonFooter, CommonTop },
    props: {},
    data() {
        return {
        }
    },
    created() {},
    mounted() {},
    methods: {
        
    }
}
</script>
<style lang='scss' scoped>
$mainWidth: 1180px;
.index-mainwidth {
    width: $mainWidth;
    padding-top: 60px;
    margin: auto;
}
</style>